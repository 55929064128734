import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GameComponent } from './game/game.component';
import { GamesComponent } from './games/games.component';

const routes: Routes = [
	{ path: 'games/:id', component: GameComponent },
	{ path: 'games', component: GamesComponent },
	{ path: '', component: GamesComponent },
	{ path: '**', component: GamesComponent },
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule { }

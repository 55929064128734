<h2>Games</h2>
<ul>
	<li>Predictions are only calculated for league-style competitions and competitions with all teams having played at least 2 home and 2 away games.</li>
	<li>Predictions represent the probability of a team winning at the start of the game given the most recent model. (No in-game predicitions)</li>
	<li>Predictions will update shortly after the completion of any game</li>
	<li>Percentages displayed are the average of 5 base models. This will be changed to an ensemble model and only represents a starting point.</li>
	<li>Click the game status to see the base model predictions.</li>
</ul>
<div>
	<a (click)='gotoLastDate()'>{{lastDate$ | async}}</a>
	| {{date$ | async}} |
	<a (click)='gotoNextDate()'>{{nextDate$ | async}}</a>
</div>
<div class='games'>
	<div *ngFor='let gameGroup of gameGroups$ | async' class='group'>
		<div class='group-header'>
			<span class='game-flag'>
				<img src='{{gameGroup.countryFlagUrl}}' alt='{{gameGroup.countryName}} flag' />
				<img class='game-logo' src='{{gameGroup.leagueLogoUrl}}' alt='{{gameGroup.leagueName}} logo' />
			</span>
			<span>({{gameGroup.countryAbbr}}) {{gameGroup.countryName}} - {{gameGroup.leagueName}}</span>
			<!--<div class='group-collapse'>+</div>-->
		</div>
		<div *ngFor='let game of gameGroup.games' class='game'>
			<div class='game-status'>
				<a routerLink='/games/{{game.gameId}}'>{{game.statusDisplay}}</a>
			</div>
			<div class='game-predictions'>
				<div *ngIf='game.prediction && game.prediction.homeDisplay'>{{game.prediction.homeDisplay}}</div>
				<div *ngIf='game.prediction && game.prediction.drawDisplay'>{{game.prediction.drawDisplay}}</div>
				<div *ngIf='game.prediction && game.prediction.awayDisplay'>{{game.prediction.awayDisplay}}</div>
			</div>
			<div class='game-results'>
				<div *ngIf='game.result && game.result.homeDisplay'>{{game.result.homeDisplay}}</div>
				<div *ngIf='game.result && game.result.drawDisplay'>{{game.result.drawDisplay}}</div>
				<div *ngIf='game.result && game.result.awayDisplay'>{{game.result.awayDisplay}}</div>
			</div>
			<div class='game-teams'>
				<div class='game-team'>
					<span>
						<img class='game-logo' src='{{game.homeTeam.teamLogoUrl}}' *ngIf='game.homeTeam.teamLogoUrl' />
					</span>
					<span>{{game.homeTeam.teamName}}</span>
				</div>
				<div class='game-team'>
					<span>
						<img class='game-logo' src='{{game.awayTeam.teamLogoUrl}}' *ngIf='game.awayTeam.teamLogoUrl' />
					</span>
					<span>{{game.awayTeam.teamName}}</span>
				</div>
			</div>
		</div>
	</div>
</div>
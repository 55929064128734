<div *ngIf="(game$ | async) as game" id='game-page'>
	<div class='breadcrumb'>
		<span class='country'>
			<img class='flag' src='{{game.countryFlagUrl}}' alt='{{game.countryName}} flag' />
			{{game.countryName}}
		</span>
		<span class='league'>
			<img class='logo' src='{{game.competitionLogoUrl}}' alt='{{game.competitionDisplayName}} logo' />
			{{game.competitionDisplayName}}
		</span>
		<div id='close-page'>
			<a routerLink='/games'>X</a>
		</div>
	</div>
	<div class='details'>
		<div *ngIf='game.venueName'>
			Stadium: {{game.venueName}}
		</div>
		<div *ngIf='game.venueCity'>
			City: {{game.venueCity}}
		</div>
		<div *ngIf='game.refereeName'>
			Referee: {{game.refereeName}}
		</div>
		<div *ngIf='game.homeTeam' class='team'>
			Home Team: <img class='logo' src='{{game.homeTeam.teamLogoUrl}}'
				alt='{{game.homeTeam.teamName}} logo' />{{game.homeTeam.teamName}}
		</div>
		<div *ngIf='game.awayTeam' class='team'>
			Away Team: <img class='logo' src='{{game.awayTeam.teamLogoUrl}}'
				alt='{{game.awayTeam.teamName}} logo' />{{game.awayTeam.teamName}}
		</div>
	</div>
	<div *ngIf='game.predictionGroups' class='predictions'>
		<div *ngFor='let group of game.predictionGroups'>
			<div id='predictions_{{group.groupName}}'>
				<table>
					<thead>
						<tr>
							<th>Model</th>
							<th>Home</th>
							<th>Draw</th>
							<th>Away</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor='let prediction of group.predictions'>
							<td>{{prediction.modelName}}</td>
							<td>{{prediction.homeWinPct | number:'1.1'}}%</td>
							<td>{{prediction.drawWinPct | number:'1.1'}}%</td>
							<td>{{prediction.awayWinPct | number:'1.1'}}%</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<!--<nav ngbNav #nav='ngbNav' class='nav-tabs'>
			<ng-container *ngFor='let group of game.predictionGroups' ngbNavItem>
				<a ngbNavLink>{{group.groupName}}</a>
				<ng-template ngbNavContent>
					<table>
						<thead>
							<tr>
								<th>Model</th>
								<th>Home</th>
								<th>Draw</th>
								<th>Away</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor='let prediction of group.predictions'>
								<td>{{prediction.modelName}}</td>
								<td>{{prediction.homeWinPct}}</td>
								<td>{{prediction.drawWinPct}}</td>
								<td>{{prediction.awayWinPct}}</td>
							</tr>
						</tbody>
					</table>
				</ng-template>
			</ng-container>
		</nav>-->
	</div>
</div>
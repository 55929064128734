import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataAndSportsApiService } from '../data-and-sports-api.service';
import { GamePageModel } from '../models/gamePageModel';

@Component({
	selector: 'app-game',
	templateUrl: './game.component.html',
	styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {
	active: string = '0';
	game$: Observable<GamePageModel | null>;

	constructor(private apiService: DataAndSportsApiService, private route: ActivatedRoute, private router: Router) {
		this.game$ = new Observable<GamePageModel | null>();
		this.route.paramMap.subscribe(x => {
			let gameId = Number(x.get('id'));
			this.game$ = this.apiService.getGamePage(gameId);
		});
	}

	ngOnInit(): void {
	}

}

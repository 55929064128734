import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { GroupedGamesModel } from './models/groupedGamesModel'
import { GamePageModel } from './models/gamePageModel'
import { stringify } from '@angular/compiler/src/util';

@Injectable({ providedIn: 'root' })
export class DataAndSportsApiService {
	//private apiUrl = 'https://api.malosh.soccer';
	private apiUrl = 'https://maloshsoccer-api.azurewebsites.net';
	// private apiUrl = 'http://dataandsports-api.azurewebsites.net';
	//private apiUrl = 'http://localhost:5000';

	httpOptions = {
		headers: this.getHttpHeaders(),
		mode: 'no-cors'
	};

	constructor(private http: HttpClient) { }

	getHttpHeaders(): HttpHeaders {
		let result: HttpHeaders = new HttpHeaders();
		//result = result.append('Content-Type', 'application/json');
		//result = result.append('Access-Control-Allow-Origin', '*');
		//result = result.append('Access-Control-Allow-Headers', 'Access-Control-Allow-Origin, Content-Type');
		return result;
	}

	getGameGroups(date?: string): Observable<GroupedGamesModel[]> {
		var url = this.apiUrl + '/games';
		if(date && (/^\d{4}-\d{2}-\d{2}$/).test(date)){
			url += "?date=" + date;
		}
		var result = this.http.get<GroupedGamesModel[]>(url, this.httpOptions);
		return result;
	}

	getGamePage(id: number): Observable<GamePageModel|null>{
		if(!id){
			return new Observable<GamePageModel|null>();
		}
		var url = this.apiUrl + '/game?id=' + id;
		var result = this.http.get<GamePageModel>(url, this.httpOptions);
		return result;
	}
}

import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { timer, Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { GroupedGamesModel } from '../models/groupedGamesModel';
import { DataAndSportsApiService } from '../data-and-sports-api.service'
import { resolveTripleslashReference } from 'typescript';
import { formatDate } from '@angular/common';


@Component({
	selector: 'app-games',
	templateUrl: './games.component.html',
	styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit, OnDestroy {
	gameGroups$: Observable<GroupedGamesModel[]>;
	date$: BehaviorSubject<string>;
	lastDate$: BehaviorSubject<string>;
	nextDate$: BehaviorSubject<string>;

	constructor(private apiService: DataAndSportsApiService, private route: ActivatedRoute, private router: Router) {
		let defaultDate: string = this.getDefaultDate();
		this.date$ = new BehaviorSubject<string>(defaultDate);
		this.lastDate$ = new BehaviorSubject<string>(this.addDaysToDateString(defaultDate, -1));
		this.nextDate$ = new BehaviorSubject<string>(this.addDaysToDateString(defaultDate, 1));
		this.gameGroups$ = new Observable<GroupedGamesModel[]>();

		this.route.queryParamMap.subscribe(params => {
			let inDate = params.get('date');
			if (inDate && (/^\d{4}-\d{2}-\d{2}$/).test(inDate)) {
				this.date$.next(inDate);
				this.lastDate$.next(this.addDaysToDateString(inDate, -1));
				this.nextDate$.next(this.addDaysToDateString(inDate, 1));
			}
			else {
				let defaultDate: string = this.getDefaultDate();
				this.date$.next(defaultDate);
				this.lastDate$.next(this.addDaysToDateString(defaultDate, -1));
				this.nextDate$.next(this.addDaysToDateString(defaultDate, 1));
			}

			this.gameGroups$ = this.setGameGroupsObservable();
		})

		// combineLatest([refreshTimer, this.date$]).subscribe(x=>{
		// 	this.games$.pipe(
		// 			switchMap(([params]) => this.apiService.getGames(this.date$.value)
		// 		)
		// 	).subscribe(games => this);
		// });

		this.gameGroups$ = this.setGameGroupsObservable();
	}

	private setGameGroupsObservable() : Observable<GroupedGamesModel[]>{
		return timer(200, 10000).pipe(
			mergeMap(() => {
				return this.apiService.getGameGroups(this.date$.value);
			})
		);
	}

	private getDefaultDate(): string {
		var now = (new Date());
		var year = now.getFullYear().toString();
		var month = (now.getMonth() + 1).toString().padStart(2, '0'); // 0-indexed months in JS/TS
		var date = now.getDate().toString().padStart(2, '0');
		var strDate = year + '-' + month + '-' + date;
		return strDate;
	}

	private addDaysToDateString(date: string, daysToAdd: number): string {
		let d: Date = new Date(date);
		d.setDate(d.getDate() + daysToAdd);
		let result: string = d.toISOString().substr(0, 10);
		return result;
	}

	public gotoGamePage(gameId: number){
		this.router.navigateByUrl('/game/' + gameId);
	}

	public gotoLastDate() {
		this.router.navigate(['/'], { queryParams: { date: this.lastDate$.value } });
	}

	public gotoNextDate() {
		this.router.navigate(['/'], { queryParams: { date: this.nextDate$.value } });
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {
	}
}
